import React from 'react';

export default function Navbar () {
  return (
    <nav className='navbar navbar-light bg-light'>
      <div className="container-fluid">
        <h1 className="display-6">
          <img className='m-2' src="https://farm.army/assets/img/farm_army_32.png" alt="Logo" />
          Coins Table
        </h1>
      </div>
    </nav>
  );
}