import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';

function inWords (value) {
  var suffixes = ["", " K", " M", " B"," T"];
  var suffixNum = Math.floor((""+value).length/3);
  var shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(2));
  if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
  }
  return shortValue+suffixes[suffixNum];
}

function App () {

  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [search, setSearch] = useState("");
  const [coin, setCoin] = useState("kcc");

  useEffect(() => {
    fetch("https://murmuring-journey-92090.herokuapp.com/https://farm.army/api/v0/farms?chain=" + coin)
    .then(res => res.json())
    .then(data => { setData(data); setTempData(data); })
    .catch(err => console.error(err));
  }, [coin])

  useEffect(() => {
    var s = search.toLowerCase();
    if(s === "") {
      setData(tempData);
    } else {
      setData(tempData.filter(v => { var name = v.name.toLowerCase(); return name.includes(s); }));
    }
  }, [search, tempData]);

  return (
    <>
      <Navbar />
      <div className="container mt-3">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-primary" onClick={() => setCoin("bsc")}>Bsc</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("polygon")}>Polygon</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("fantom")}>Fantom</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("kcc")}>Kcc</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("harmony")}>Harmony</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("celo")}>Celo</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("moonriver")}>MoonRiver</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("cronos")}>Cronos</button>
        <button type="button" class="btn btn-primary" onClick={() => setCoin("moonbeam")}>MoonBeam</button>
      </div>
      </div>
      <div className="container mt-3">
        <input className='form-control mb-3 text-primary' placeholder='Search' type="text" onChange={e => setSearch(e.target.value)} />
        <table className='table'>
          <thead className='border rounded-pill'>
            <tr>
              <th className='col'><u>Name</u></th>
              <th className='col'><u>TVL</u></th>
              <th className='col'><u>Yield</u></th>
              <th className='col'><u>Platform</u></th>
            </tr>
          </thead>
          <tbody>
            {data.map(v => {
              var tvl = v.tvl ? v.tvl.usd : 0;
              var daily = v.yield ? v.yield.daily : 0;
              if (daily === undefined) {
                daily = 0;
              }
              else if (daily !== 0) {
                daily = daily.toString();
                daily = daily.substring(0, 4);
              }  
              return (
              <tr className='border'>
                <th className="col border-0 d-flex">
                  <img style={{'width': '50px'}} src={`https://farm.army${v.icon}`} alt="LogoToken" />
                  <div>
                    <h6 className='ps-2 m-0 fw-bold'>{v.name}</h6>
                    <p className="ps-2 fs-6 m-0 lead">{v.provider.label}</p>
                  </div>
                </th>
                <th className='col'>
                  <div>
                    <h6 className='m-0 fw-bold'>$ {inWords(Math.round(tvl, 4))}</h6>
                    <p className="ps-3 fs-6 m-0 lead">TVL</p>
                  </div>
                </th>
                <th className='col'>
                  <div>
                    <h6 className='m-0 fw-bold'>{inWords(Math.round(v.yield ? v.yield.apy : 0, 2))}%</h6>
                    <p className="fs-6 m-0 lead">{daily}%</p>
                  </div>
                </th>
                <th className='col'>
                  <img style={{'width': '50px'}} src={`https://farm.army${v.provider.icon}`} alt="LogoPlatform" />
                </th>
              </tr>
            )
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default App;